<template>
    <v-tooltip top :disabled="enabled">
        <template v-slot:activator="{ on }">
            <div v-on="on">
                <vue2-dropzone
                    ref="dropzone"
                    v-if="!loading"
                    id="venueFeaturedImageDropzone"
                    :options="dropzoneOptions"
                    @vdropzone-success="handleSuccess"
                    @vdropzone-removed-file="handleRemovedFile"
                    @vdropzone-mounted="checkStatus"
                />
            </div>
        </template>
        <span>{{ tooltipText }}</span>
    </v-tooltip>
</template>

<script>
import vue2Dropzone from "vue2-dropzone"
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
    name: "FeaturedImage",
    components: {vue2Dropzone},
    props: {
        value: {
            type: String,
            default: () => null
        },
        enabled: {
            type: Boolean,
            default: () => true
        },
        tooltipText: {
            type: String,
            default: () => "Looks like you want an upgrade (we don't blame you) - this feature is available on a paid plan!"
        },
    },
    data() {
        return {
            dropzoneOptions: {
                url: '/api/upload',
                thumbnailWidth: 250,
                maxFilesize: 100,
                addRemoveLinks: true,
                headers: {
                    Authorization: null
                },
                params: {
                    directory: '/venues/featured'
                },
                enabled: this.enabled
            },
            loading: true,
        }
    },
    methods: {
        handleSuccess(file, res) {
            this.$emit('input', res.url);
        },

        handleRemovedFile() {
            this.$emit('input', null);
        },

        async setDropzoneAuthToken() {
            const token = await localStorage.getItem('auth_token_default')
            this.dropzoneOptions.headers.Authorization = "Bearer " + token
        },

        setExistingFile() {
            if (!this.value) {
                return
            }

            const file = {size: 123, name: this.value, type: "image/png"};
            this.$refs.dropzone.manuallyAddFile(file, this.value);
        },

        checkStatus() {
            if (!this.enabled) {
                this.$refs.dropzone.disable()

                return
            }

            this.setExistingFile()
        }
    },
    async mounted() {
        await this.setDropzoneAuthToken()

        this.loading = false
    },
    watch: {
        value() {
            this.$refs.dropzone.removeAllFiles();

            if (!this.value) {
                return
            }

            const file = {
                size: 123,
                name: this.value,
                url: this.value,
                thumbnail: this.value
            };

            this.$refs.dropzone.manuallyAddFile(file, this.value);
            this.$emit('input', this.value);
        }
    }
}
</script>

<style scoped>

</style>
