<template>
    <dashboard-layout>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        <v-col cols="auto">
                            Venue
                        </v-col>
                        <v-col cols="auto">
                            <v-btn :to="{name: 'venue.public', params: {slug: venue.slug}}">
                                <v-icon>mdi-eye</v-icon>
                                View
                            </v-btn>
                        </v-col>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-form>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                outlined
                                                :loading="venueLoading"
                                                v-model="venue.name"
                                                :error-messages="venueErrors.name"
                                                label="Venue name"
                                            />
                                        </v-col>

                                        <v-col cols="12" sm="6">
                                            <v-rating
                                                class="justify-content-start"
                                                icon-label="Price scale"
                                                v-model="venue.price_scale"
                                                color="#85bb65"
                                                full-icon="mdi-currency-usd"
                                                empty-icon="mdi-currency-usd"
                                                background-color="#9a9a9a"
                                                large
                                            ></v-rating>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6" md="3">
                                            <city-selector
                                                v-if="!venueLoading"
                                                return-object
                                                v-model="venue.city"
                                                outlined
                                                hide-details="auto"
                                            />
                                        </v-col>

                                        <v-col cols="12" sm="6" md="3">
                                            <v-text-field
                                                outlined
                                                :loading="venueLoading"
                                                v-model="venue.address"
                                                label="Address"
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6" md="2">
                                            <v-text-field
                                                outlined
                                                :loading="venueLoading"
                                                v-model="venue.zip"
                                                label="Zip Code"
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6" md="3">
                                            <v-text-field
                                                outlined
                                                :loading="venueLoading"
                                                v-model="venue.phone_number"
                                                label="Phone number"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                outlined
                                                :loading="venueLoading"
                                                v-model="venue.call_to_action_text"
                                                :error-messages="venueErrors.call_to_action_text"
                                                label="Call to action text"
                                            />
                                        </v-col>

                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                outlined
                                                :loading="venueLoading"
                                                v-model="venue.call_to_action_url"
                                                :error-messages="venueErrors.call_to_action_url"
                                                label="Call to action URL"
                                            />
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col md="6">
                                            <subscription-text-input
                                                outlined
                                                :loading="venueLoading"
                                                ability="haveWebsiteUrl"
                                                resource="venue"
                                                v-model="venue.website_url"
                                                :error-messages="venueErrors.website_url"
                                                label="Website URL"
                                            />
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="12" xl="3">
                                            <v-switch
                                                v-model="venue.has_custom_calendar_url"
                                                :error-messages="venueErrors.has_custom_calendar_url"
                                                label="Use custom calendar"
                                            />
                                        </v-col>
                                        <v-col cols="12" xl="9">
                                            <subscription-text-input
                                                outlined
                                                :loading="venueLoading"
                                                v-if="venue.has_custom_calendar_url || venueErrors.custom_calendar_url"
                                                v-model="venue.custom_calendar_url"
                                                :error-messages="venueErrors.custom_calendar_url"
                                                label="Custom calendar URL"
                                                ability="haveWebsiteUrl"
                                                resource="venue"
                                            />
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col xl="3">
                                            <featured-image
                                                v-if="!venueLoading"
                                                :enabled="featuredImageIsEnabled"
                                                v-model="venue.featured_image"
                                            />
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="this.venue.gallery.length > maxGalleryFiles">
                                        <v-col>
                                            <v-alert type="warning">
                                                Due to your subscription restrictions, only {{ maxGalleryFiles }}
                                                gallery image(s) will be shown
                                            </v-alert>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <gallery-dropzone
                                                v-if="!venueLoading"
                                                :enabled="galleryIsEnabled"
                                                :max-files="maxGalleryFiles"
                                                v-model="venue.gallery"
                                            />
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col>
                                            <v-btn color="success" @click="save">
                                                Save
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card class="mx-auto my-auto">
                    <v-card-title>
                        Contact info
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-row>
                                    <v-col>
                                        <avatar-edit
                                            v-model="user.avatar_url"
                                            @error="(m) => this.avatarErr = m"
                                            @file-added="avatarErr = null"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row v-if="avatarErr">
                                    <v-col>
                                        <v-alert type="error">
                                            {{ avatarErr }}
                                        </v-alert>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col>
                                <v-form>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" lg="6">
                                                <v-text-field
                                                    v-model="user.first_name"
                                                    label="First name"
                                                    placeholder=""
                                                />
                                            </v-col>
                                            <v-col cols="12" lg="6">
                                                <v-text-field
                                                    v-model="user.last_name"
                                                    label="Last name"
                                                    placeholder=""
                                                />
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <v-btn @click="handleUpdateProfile">Update</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-form>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

        </v-row>
    </dashboard-layout>
</template>

<script>
import User from "@/models/User";
import AvatarEdit from "@/components/AvatarEdit";
import Venue from "@/models/Venue";
import FeaturedImage from "@/components/venue/FeaturedImage";
import GalleryDropzone from "@/components/venue/GalleryDropzone";
import DashboardLayout from "@/layouts/DashboardLayout";
import SubscriptionTextInput from "@/components/SubscriptionTextInput";
import CitySelector from "@/components/CitySelector";

export default {
    name: "Profile",
    components: {CitySelector, SubscriptionTextInput, DashboardLayout, GalleryDropzone, FeaturedImage, AvatarEdit},
    computed: {
        featuredImageIsEnabled() {
            if (!this.venue.id) {
                return false;
            }

            if (!this.can('haveImage', 'venue')) {
                return false
            }

            if (this.can('haveMultipleImages', 'venue')) {
                return true
            }

            return !this.venue.gallery.length ? true : !!this.venue.featured_image;
        },
        galleryIsEnabled() {
            if (!this.venue.id) {
                return false;
            }

            if (this.can('haveMultipleImages', 'venue')) {
                return true
            }

            return this.can('haveImage', 'venue') ? !this.venue.featured_image : false;
        },
        maxGalleryFiles() {
            return this.can('haveMultipleImages', 'venue') ? 100 : 1;
        }
    },
    data: function () {
        return {
            user: {},
            venueLoading: false,
            venue: {
                first_name: null,
                last_name: null,
                call_to_action_text: null,
                call_to_action_url: null,
                price_scale: null,
                has_custom_calendar_url: null,
                custom_calendar_url: null,
                gallery: []
            },
            avatarErr: null,
            venueErrors: [],
            priceScales: [
                1,
                2,
                3,
                4,
                5,
            ]
        }
    },
    methods: {
        async handleUpdateProfile() {
            const user = new User(this.user);
            this.user = await user.save()
        },
        async loadVenue() {
            this.venueLoading = true

            this.venue = await Venue
                .where('user_id', this.$auth.user()['id'])
                .include([
                    'venue_profile_views_count',
                    'venue_website_views_count',
                    'venue_call_to_action_clicks_count',
                    'city',
                ])
                .first()

            this.venueLoading = false
        },
        async save() {
            this.venueLoading = true

            try {
                this.venue = await this.venue.save()
            } catch (e) {
                this.venueErrors = e.response.data.errors
            }

            this.venueLoading = false
        }
    },
    async mounted() {
        this.user = this.$auth.user()
        await this.loadVenue()
    },
    watch: {
        "venue.city": function (val) {
            this.venue.city_id = val.id
        }
    },
}
</script>

<style scoped>

</style>
