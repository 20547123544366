<template>
    <v-autocomplete
        v-model="city"
        :items="cities"
        v-bind="$attrs"
        :error-messages="errorMessages"
        :loading="loading"
        :search-input.sync="query"
        item-text="display_name"
        label="City, state"
        return-object
    >
        <template v-slot:append-item>
            <div v-intersect="endIntersect"/>
        </template>
    </v-autocomplete>
</template>

<script>
import City from "@/models/City";

export default {
    name: "CitySelector",
    props: {
        value: {
            type: [Number, Object]
        },
        errorMessages: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            loading: false,
            query: null,
            city: null,
            cities: [],
            page: 1,
            morePages: false,
        }
    },
    methods: {
        async fetchCities() {
            this.loading = true
            this.page = 1

            const query = City.page(1).limit(10)

            if (this.query) {
                query.where('query', this.query)
            }

            const res = await query.get();
            this.morePages = !!res['next_page_url']

            const cities = res['data'];

            if (this.city) {
                cities.push(this.city)
            }

            this.cities = cities

            this.loading = false
        },
        async endIntersect(entries, observer, isIntersecting) {
            if (!this.morePages || !isIntersecting) {
                return
            }

            const query = City.page(this.page + 1).limit(10)

            if (this.query) {
                query.where('query', this.query)
            }

            const res = await query.get();
            this.morePages = !!res['next_page_url']
            this.page = res['current_page']
            this.cities = this.cities.concat(res['data'])
        }
    },
    mounted() {
        if (this.value) {
            this.city = this.value
        }

        this.fetchCities()
    },
    watch: {
        city(val) {
            if (!val) {
                this.$emit('input', null);
                return
            }

            val = this.$attrs['return-object'] === '' || !!this.$attrs['return-object'] ? val : val.id;

            this.$emit('input', val);
        },

        query: function () {
            this.fetchCities()
        },

        value: function (val) {
            const cityVal = 'id' in (this.city ?? {}) ? this.city.id : this.city

            if (val !== cityVal) {
                this.city = val
            }
        }
    }
}
</script>

<style scoped>

</style>
