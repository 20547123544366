<template>
    <v-tooltip top :disabled="enabled">
        <template v-slot:activator="{ on }">
            <div v-on="on">
                <vue2-dropzone
                    ref="myVueDropzone"
                    v-if="!loading"

                    id="venueGalleryDropzone"
                    :options="dropzoneOptions"
                    @vdropzone-success="handleSuccess"
                    @vdropzone-removed-file="handleRemovedFile"
                    @vdropzone-mounted="checkStatus"
                />
            </div>
        </template>
        <span>{{ tooltipText }}</span>
    </v-tooltip>
</template>

<script>
import vue2Dropzone from "vue2-dropzone"
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
    name: "GalleryDropzone",
    components: {vue2Dropzone},
    props: {
        value: {
            type: Array,
            default: () => []
        },
        enabled: {
            type: Boolean,
            default: () => true
        },
        maxFiles: {
            type: Number,
            default: () => null
        },
        tooltipText: {
            type: String,
            default: () => "Looks like you want an upgrade (we don't blame you) - this feature is available on a paid plan!"
        },
    },
    data() {
        return {
            dropzoneOptions: {
                url: '/api/upload',
                thumbnailWidth: 250,
                maxFilesize: 100,
                addRemoveLinks: true,
                maxFiles: this.maxFiles,
                headers: {
                    Authorization: null
                },
                params: {
                    directory: '/venues/gallery'
                }
            },
            loading: true,
        }
    },
    methods: {
        handleSuccess(file, res) {
            this.value.push(res.url)
            this.$emit('input', this.value);
        },
        handleRemovedFile(file) {
            this.$emit('input', this.value.filter(url => file.name !== url));
        },
        setDropzoneAuthToken() {
            const token = localStorage.getItem('auth_token_default')
            this.dropzoneOptions.headers.Authorization = "Bearer " + token
        },
        addExistingFiles() {
            this.value.forEach(url => {
                const file = {size: 123, name: url, type: "image/png"};
                this.$refs.myVueDropzone.manuallyAddFile(file, url);
            })
        },
        checkStatus() {
            if (!this.enabled) {
                this.$refs.myVueDropzone.disable()

                return
            }

            this.addExistingFiles()
        }
    },
    async mounted() {
        this.setDropzoneAuthToken()

        this.loading = false
    }
}
</script>

<style scoped>

</style>
